<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            :url="saveUrl"
            v-if="editable"
            :isSubmit="isSave"
            :param="data"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveMst"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-select
            required
            type="edit"
            codeGroupCd="CHM_DANGER_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            label="구분"
            v-model="data.chmDangerTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            required
            :editable="editable"
            label="설치장소"
            name="chmDangerArea"
            v-model="data.chmDangerArea">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            label="품명 표기"
            name="chmDangerMstName"
            v-model="data.chmDangerMstName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :editable="editable"
            label="지정수량 표기"
            name="chmDangerCount"
            v-model="data.chmDangerCount">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="data"
            label="안전관리자"
            type="user"
            name="chmDangerManageUserId"
            v-model="data.chmDangerManageUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="data"
            label="관리자"
            type="user"
            name="chmDangerUserId"
            v-model="data.chmDangerUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="data"
            label="안전대리자"
            type="user"
            name="chmDangerSafetyUserId"
            v-model="data.chmDangerSafetyUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :editable="editable"
            label="주용도"
            name="chmDangerMainUse"
            v-model="data.chmDangerMainUse">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant 
            required
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="data.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="사용여부"
            name="useFlag"
            v-model="data.useFlag"
          />
        </div>
      </template>
    </c-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="table1"
        title="저장품목"
        :columns="grid1.columns"
        gridHeight="200px"
        :data="data.typeList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="저장품목을 추가하세요."
        :hideBottom="true"
        :editable="editable"
        rowKey="chmDangerMstTypeId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addType" />
            <c-btn v-if="editable && data.typeList.length > 0" :showLoading="false" label="제외" icon="remove" @btnClicked="removeType" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'calcuMethod'">
            {{props.row['calcuOriginal'] * props.row['calcuMultiple'] | toThousandFilter}}
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="table2"
        title="점검항목 목록"
        :columns="grid2.columns"
        gridHeight="400px"
        :data="data.itemList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="점검항목을 추가하세요."
        :hideBottom="true"
        :editable="editable"
        rowKey="chmDangerMstItemId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable && data.itemList.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'danger-master-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerMstId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      grid1: {
        columns: [
          {
            required: true,
            name: 'dangerItemName',
            field: 'dangerItemName',
            label: '품목',
            style: 'width:300px',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'chmDangerTypeName',
            field: 'chmDangerTypeName',
            label: '품목 명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            style: 'width:150px',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'calcuOriginal',
            field: 'calcuOriginal',
            label: '품목별 지정수량',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'calcuMultiple',
            field: 'calcuMultiple',
            label: '배수(사업장 허가)',
            style: 'width:150px',
            type: 'number',
            sortable: false,
          },
          {
            name: 'calcuMethod',
            field: 'calcuMethod',
            label: '적용 지정수량',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
        ],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'chmDangerItemName',
            field: 'chmDangerItemName',
            label: '점검항목',
            style: 'width:300px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            style: 'width:40px',
            align: 'left',
            type: 'number',
            sortable: false,
          },
        ],
        height: '300px'
      },
      data: {
        chmDangerMstId: '',
        plantCd: '',
        chmDangerArea: '',
        chmDangerManageUserId: '',
        chmDangerMstName: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerCount: '',
        chmDangerMainUse: '',
        useFlag: 'Y',
        chmDangerTypeCd: null,
        
        typeList: [],
        deleteTypeList: [],

        itemList: [],
        deleteItemList: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      saveUrl: 'transactionConfig.chm.danger.master.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      selectedRow: null,
      selectedCol: null
    };
  },
  computed: {
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.chm.danger.master.get.url;
      this.insertUrl = transactionConfig.chm.danger.master.insert.url;
      this.updateUrl = transactionConfig.chm.danger.master.update.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.chmDangerMstId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.chmDangerMstId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    saveMst() {
      if (this.data.chmDangerMstId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid1.columns, this.data.typeList)) {
            if (this.$comm.validTable(this.grid2.columns, this.data.itemList)) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('APP_VALID_ERROR');
            }
          }
        }
      });
    },
    saveCallback(result) {
      this.popupParam.chmDangerMstId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    addType() {
      this.popupOptions.title = '위험물 지정수량';
      this.popupOptions.target = () => import(`${'@/pages/chm/base/dangerItemPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupDangerItem;
    },
    closePopupDangerItem(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let _chmDangerMstName = '';
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.data.typeList.push({
            chmDangerMstId: this.popupParam.chmDangerMstId,
            chmDangerMstTypeId: uid(),
            chmDangerTypeName: '',
            chmDangerItemId: item.chmDangerItemId,
            dangerItemName: item.regulatedItemName + ' - ' + item.dangerItemName,
            casNo: '',
            calcuMethod: item.quantity,
            calcuOriginal: item.quantity,
            calcuMultiple: 1,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
          _chmDangerMstName = _chmDangerMstName + (_chmDangerMstName == '' ? '' : ',') + item.regulatedItemName + item.dangerItemName.split('.')[1];
        })
      }
      if (!this.data.chmDangerMstName) {
        this.data.chmDangerMstName = _chmDangerMstName;
      }
    },
    removeType() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteTypeList) {
            this.data.deleteTypeList = []
          }
          if (this.$_.findIndex(this.data.deleteTypeList, { chmDangerMstTypeId: item.chmDangerMstTypeId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteTypeList.push(item)
          }
          this.data.typeList = this.$_.reject(this.data.typeList, item)
        })
      }
    },
    addItem() {
      this.data.itemList.push({
        chmDangerMstId: this.popupParam.chmDangerMstId,
        chmDangerMstItemId: uid(),
        chmDangerItemName: '',
        sortOrder: this.data.itemList.length + 1,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeItem() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteItemList) {
            this.data.deleteItemList = []
          }
          if (this.$_.findIndex(this.data.deleteItemList, { chmDangerMstItemId: item.chmDangerMstItemId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteItemList.push(item)
          }
          this.data.itemList = this.$_.reject(this.data.itemList, item)
        })
      }
    },
  }
};
</script>
